const menus = [
  {
    id: 1,
    name: 'Home',
    links: '/',
  },
  {
    id: 2,
    name: 'Donations',
    links:  '/project_v1',
    /* namesub: [
      {
        id: 1,
        sub: 'Donated items',
        links: '/project_v1',
      },
      {
        id: 2,
        sub: 'NFT Market',
        links: '/project_v2',
      },
      {
        id: 3,
        sub: 'My NFT',
        links: '/project_v3',
      },
       
    ], */
  },

  {
    id: 3,
    name: 'NFT',
    links:  '/project_v5', 
  },
  {
    id: 4,
    name: 'Staking',
    links:  '/project_v5', 
  },
  {
    id: 5,
    name: 'Escrow',
    links:  '/project_v5', 
  },
  /* {
    id: 3,
    name: 'Page',
    links: '#',
    namesub: [
      {
        id: 1,
        sub: 'Token',
        links: '/token',
      },
      {
        id: 2,
        sub: 'Connect Wallet',
        links: '/connect',
      },
      {
        id: 3,
        sub: 'Team Details',
        links: '/team_details',
      },
      {
        id: 4,
        sub: 'Submit IGO on chain',
        links: '/submit_igo',
      },
      {
        id: 5,
        sub: 'FAQs',
        links: '/faqs',
      },
      {
        id: 6,
        sub: 'Login',
        links: '/login',
      },
      {
        id: 7,
        sub: 'Register',
        links: '/register',
      },
      {
        id: 8,
        sub: 'Forget Password',
        links: '/forgetPass',
      },
    ],
  }, */
  {
    id: 6,
    name: 'White Paper',
    links: '/Roadmap',
  },
  /* {
    id: 5,
    name: 'Blog',
    links: '#',
    namesub: [
      {
        id: 1,
        sub: 'Blog Grid',
        links: '/blog_grid',
      },
      {
        id: 2,
        sub: 'Blog List',
        links: '/blog_list',
      },
      {
        id: 3,
        sub: 'Blog Detail',
        links: '/blog_details',
      },
    ],
  }, */
  {
    id: 7,
    name: 'Contact',
    links: 'contact',
  },
]

export default menus
