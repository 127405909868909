import { Language } from '@pancakeswap/uikit'

 
export const EN: Language = { locale: 'en', language: 'English', code: 'en' }
 
export const ZH: Language = { locale: 'zh', language: '繁體中文', code: 'zh' }

export const languages = {
 
  'en': EN, 
  'zh': ZH,
}

export const languageList = Object.values(languages)
