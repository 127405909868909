import React, { useState, useEffect } from 'react'

import PageTitle from '../components/pagetitle'
import CTA from '../components/cta/cta_v2'
import { Link } from 'react-router-dom'

import ProjectItem from '../components/project/project_detail'

import { Tab, Tabs, TabList, TabPanel  } from 'react-tabs'

import {
  Box,
  alertVariants,
  Alert,
  Button, Input, AddIcon, RemoveIcon
} from '@pancakeswap/uikit'

import data from '../assets/fake-data/data-project'
import { getContractHandler, _getAddress, _getProvider, useWalletHook } from '../utils/ethereum'
import { Constants } from '../utils/Config'
import useToast from '../utils/useToast'
import { useTranslation } from '../utils/contexts/Localization'

import { BigNumber, Contract, providers, utils } from 'ethers'



function Init(props) {
  const { itemid,selectedIndex } = props;

  
  console.log("item:", itemid ,selectedIndex)
  
  const [order, setOrder] = useState() 
  const { toastSuccess, toastError } = useToast()
  const { t } = useTranslation();

  const [nft, setNFT] = useState(null)


  const queryTokens = async () => {
    const MarketPlace = await getContractHandler('MarketPlace')
    if (!MarketPlace) return;
    const att = await MarketPlace.getOrderByIndex(itemid)
    console.log("attattattattatt",att)
    setOrder(att);
  }


  const queryImg = async () => {
    console.log("开始亲求");
    const res = require("src/utils/json/"+itemid+".json");
    console.log("resres", res);
    setNFT(res)
}
 
  useEffect(() => { 
        queryImg()
        queryTokens();  
  }, []);

  const buyBox = async () => {
    try {

      const account = await _getAddress()
      const usdt = await getContractHandler('USDT')
      const MarketPlace = await getContractHandler('MarketPlace')
      if (!MarketPlace) return;
      // const att = await PiPandaMint.openBox(ids)
      //检查授权
      //如果没有授权，先提示授权
      const myUSDT = await usdt.balanceOf(account) 

      if (parseFloat(utils.formatEther(myUSDT)) <  parseFloat(utils.formatEther(order.price))) {
        toastError('Insufficient Balance')
        return false;
      } 
      const approvePidao = await usdt.allowance(account, Constants.Contract.MarketPlace)
      if (parseFloat(utils.formatEther(approvePidao)) <= parseFloat(utils.formatEther(myUSDT))) {
        const approve = await usdt.approve(Constants.Contract.MarketPlace, "0xffffffffffffffffffffffffffffffffffffffff")
        return false;
      } 
      await MarketPlace.buy(order.id)
      toastSuccess(t('Successfully submit Buy NFT!'))

    } catch (e) {

      toastError(e?.message)
      console.error(e?.message)
    }

  }


  const cancel = async () => {
    try {

      const account = await _getAddress()
       const MarketPlace = await getContractHandler('MarketPlace')
      if (!MarketPlace) return;
      // const att = await PiPandaMint.openBox(ids)
   
      await MarketPlace.cancelOrder(order.id)
      toastSuccess('Successfully submit Cancel NFT!')

    } catch (e) {

      toastError(e?.message)
      console.error(e?.message)
    }

  }




 

  return (
    <div
      key={itemid}
      className="content-inner project-box-style4"
    >

      <div className="content">

        <div className="image" style={{ width: "100%" }}> 
          <img src={nft?.image} alt="" />  
        </div>
        <h5 className="heading">

        </h5>

        <ul style={{ width: "100%" }}>

          <li>
            <p className="text">ID</p>
            <p className="price">{order?.nftId?utils.formatUnits(order?.nftId,0):""}</p>
          </li>
          <li>
            <p className="text">{t("Price")}</p>
            <p className="price">{order?.price?utils.formatUnits(order?.price,6):""} </p>
          </li>

          <li>
            <p className="text">{t("Option")}</p>
            {selectedIndex === 0 && <p className="price2" onClick={buyBox}>{t("Buy")}</p>}
            {selectedIndex === 1 && <p className="price2" onClick={cancel}>{t("Cancel")} </p>}

          </li>
        </ul>
      </div>
    </div>
  );
}

function Project2(props) {
  const [dataTab] = useState([
    {
      id: 1,
      title: 'Market',
    },
      {
      id: 2,
      title: 'My List',
    } 
  ])


 
  const [selectedIndex,setSelectIndex] = useState(0) 
  console.log("selectedIndex",selectedIndex)

  const [start, setStart] = useState(0)
   const [total, setTotal] = useState(0)
  const [nowpage, setNowPage] = useState(1)
  const { t } = useTranslation();
  const [nowpageV, setNowPageV] = useState()

  const [queryTotal, setQueryTotal] = useState(0)

  const [myTotal, setMyTotal] = useState(0)


  const { wallet, chain } = useWalletHook();
/*   const [tab23, setTab23] = useState(0)

  const changeTab = async (idx) => {
    setTab23(idx)
  } */


  
  const queryTokens = async () => {

    const PiPandaNFT = await getContractHandler('PiPandaNFT')
    const MarketPlace = await getContractHandler('MarketPlace')

    
    console.log("PiPandaNFTwallet", wallet)
    if (!PiPandaNFT) return;

    let total
    console.log("selectedIndexselectedIndex", selectedIndex)
    if(selectedIndex ==0){
      total = await MarketPlace.ordersCount()
    }else if(selectedIndex ==1){
      //进来了
      console.log("查询个人钱包了", wallet)
      total = await MarketPlace.userOrdersCount(wallet)
    }

    setTotal(utils.formatUnits(total, 0));


    let queryTotal = utils.formatUnits(total, 0) ;

    if (queryTotal > (start + 12)) {
      queryTotal = start + 12;
    }

    setQueryTotal(queryTotal)


    //我的列表
 

  }

  useEffect(() => {
    if (wallet && chain) {
      queryTokens();
    }

  }, [wallet + chain + start+selectedIndex]);

  console.log("total", total)


  const changeNumber = (e) => {
    setNowPageV(e.target.value);
  }

  return (
    <div className="inner-page">
      {<PageTitle />}

      <section className="tf-section project">
        <div className="container">


          <div className="row">
            <div className="col-md-12">
              <div
                className="flat-tabs"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <Tabs         selectedIndex={selectedIndex}   
                onSelect ={ (index) => {
                  setStart(0)
                  console.log("index",index)
                  setSelectIndex(index)}}>
                  <div className="wrapper_menutab">
                    <TabList className="menu-tab">
                      {dataTab.map((idx,ide) => (
                        <Tab className="fs-14 h6" key={idx.id}  tabIndex = {ide}>
                          {t(idx.title)}
                        </Tab>
                      ))}
                    </TabList>
                    <div
                      className="seclect-box"
                      data-aos="fade-in"
                      data-aos-duration="800"
                    >
                      <div className="dropdown selector-drop" id="category">
                        <Link
                          to="#"
                          className="btn-selector btn-link"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="boder"></span>{t("All access")}
                        </Link>
                        <ul className="dropdown-menu dropdown-menu-right">
                          <li
                            className="dropdown-item active"
                            data-toggle="modal"
                            data-target="#delete_client"
                          >
                            {t("Calvary Deneral")}
                            Forgotten Samurai
                          </li>
                          <li
                            className="dropdown-item"
                            data-toggle="modal"
                            data-target="#edit_client"
                          >
                            {t("Calvary Deneral")}
                            Star Batter
                          </li>
                          <li
                            className="dropdown-item"
                            data-toggle="modal"
                            data-target="#edit_client"
                          >
                            {t("Calvary Deneral")}
                            
                          </li>
                        </ul>
                      </div>
                      {/* <div className="dropdown selector-drop" id="artworks">
                        <Link
                          to="#"
                          className="btn-selector btn-link"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="boder"></span>All Block Chain
                        </Link>

                        <ul className="dropdown-menu dropdown-menu-right">
                          <li
                            className="dropdown-item active"
                            data-toggle="modal"
                            data-target="#delete_client"
                          >
                            Sort by Popularity
                          </li>
                          <li
                            className="dropdown-item"
                            data-toggle="modal"
                            data-target="#edit_client"
                          >
                            Sort by Latest
                          </li>
                          <li
                            className="dropdown-item"
                            data-toggle="modal"
                            data-target="#edit_client"
                          >
                            Sort by View
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>


                  <TabPanel className="content-tab mt40">
                    <div className="content-inner project-box-style6_wrapper">
                      { Array.from({ length: queryTotal - start   }, (_, i) => start + i).map((item) => (
                           <Init itemid={item} selectedIndex = {selectedIndex}/>  
                        ))}
                    </div>
                  </TabPanel>


                  <TabPanel className="content-tab mt40">
                    <div className="content-inner project-box-style6_wrapper">
                      { Array.from({ length: queryTotal - start   }, (_, i) => start + i).map((item) => (
                           <Init itemid={item} selectedIndex = {selectedIndex}/>  
                        ))}
                    </div>
                  </TabPanel>

                </Tabs>
              </div>
            </div>
            <div className="col-md-12">

              <ul
                className="panigation mt6"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <li>  <span>{t("Total ")}：{total}</span>&nbsp;&nbsp;&nbsp;</li>
                <li>  <span>   </span></li>
                <li>  <span>{t("Total Page")} ：{Math.ceil(Number(total) / 12)}</span>&nbsp;&nbsp;&nbsp;</li>

                <li>  <Input onChange={changeNumber} value={nowpageV} style={{ width: "60px" }} /> </li>
                <li>  <Button style={{ marginLeft: "10px" }} onClick={() => {
                  if (nowpageV && nowpageV > 0 && nowpageV <= Math.ceil(Number(total))) {
                    setNowPage(nowpageV);
                    setStart((nowpageV - 1) * 12);
                 
                  }
                }

                }>{t("Jump")}</Button></li>
              </ul>


            </div>
          </div>
        </div>
      </section>

      {/*  {<CTA />} */}
    </div>
  )
}

export default Project2
