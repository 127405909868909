import React from 'react'

import PageTitle from '../components/pagetitle'
import CTA from '../components/cta/cta_v2'
import { Link } from 'react-router-dom'

import data from '../assets/fake-data/data-project'

function Project5(props) {
  return (
    <div className="inner-page project-grid-5">
      {<PageTitle />}

      <section className="tf-section project_1">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="tf-title left mb40"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <h2 className="title">Coming Soon</h2>
              </div>
            </div>
          </div>  
        </div>
      </section>

       

      {<CTA />}
    </div>
  )
}

export default Project5
