import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import Button from '../../button';
import { ImgUrl } from '../../../utils/Config';

import { BigNumber, Contract, providers, utils } from 'ethers'
import useToast from '../../../utils/useToast'
import { getContractHandler, _getAddress } from '../../../utils/ethereum'
import {  Constants } from '../../../utils/Config'
import { useTranslation } from '../../../utils/contexts/Localization'
import {
    Input, AddIcon, RemoveIcon
} from '@pancakeswap/uikit'
import styled from 'styled-components'

const StyleInput = styled(Input)`
  width: 260px;
  background-color: rgba(0, 0, 0, 0);
  border:1px solid rgba(0, 0, 0, 0)

  border-bottom: 1px solid #ffffff85;
  text-align: left;
  color: #000  ;
  font-color: #000;
  margin-top: -4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  height:30px;
`


function Banner(props) {
    const { item, isBox } = props;
    console.log("item:", item, isBox)
    const ids = utils.formatUnits(item._hex, 0);
    const [attr, setAttr] = useState("")
    const [price, setPrice] = useState(0)

    const [nft, setNFT] = useState(null)
    const isNFT = isBox;

    const { toastSuccess, toastError } = useToast()
    const { t } = useTranslation();



    const changePrice = (e)=>{
        setPrice(e.target.value); 
   }

    const queryTokens = async () => {

        const PiPandaNFT = await getContractHandler('PiPandaNFT')

        if (!PiPandaNFT) return;

        const att = await PiPandaNFT.getAttribute(ids)

        setAttr(att); 

    }

    


    const queryImg = async () => {
        console.log("开始亲求");
        const res = require("src/utils/json/"+ids+".json");
        console.log("resres", res);
        setNFT(res)
    }

    useEffect(() => {
        if (isNFT) {
            //  queryTokens(); 
            queryImg();
        }

    }, []);

    const openBox = async () => {
        try {

           const account  =await _getAddress()

            const batchOpen = await getContractHandler('BatchOpen')
            const piPandaNFT = await getContractHandler('PiPandaNFT')
            if (!batchOpen) return;
            // const att = await PiPandaMint.openBox(ids)
            //检查授权
            //如果没有授权，先提示授权

            const is=  await  piPandaNFT.isApprovedForAll(account,Constants.Contract.BatchOpen)
            if(!is){
                toastError(t("NO Approved"))
                await  piPandaNFT.setApprovalForAll( Constants.Contract.BatchOpen,true);
                toastSuccess(t('Approved'))
                return ;
            } 
            await batchOpen.openBoxNotLimit(ids)
            toastSuccess('Successfully submit Open Box!')

        } catch (e) {
 
            toastError(e?.message)
            console.error(e?.message)
        }

    }

    const sellBox = async () => {


        try {

            const account  =await _getAddress()
 
             const MarketPlace = await getContractHandler('MarketPlace')
             const piPandaNFT = await getContractHandler('PiPandaNFT')
             if (!MarketPlace) return;
             // const att = await PiPandaMint.openBox(ids)
             //检查授权
             //如果没有授权，先提示授权

             if(price<=0){
                toastError("请输入正确金额")
                return
             }
 
             const is=  await  piPandaNFT.isApprovedForAll(account,Constants.Contract.MarketPlace)
             if(!is){
                 toastError(t("NO Approved"))
                 await  piPandaNFT.setApprovalForAll( Constants.Contract.MarketPlace,true);
                 toastSuccess(t('Approved'))
                 return ;
             } 
             await MarketPlace.createOrder(Constants.Contract.PiPandaNFT,ids,price*1000000)
             toastSuccess('Successfully submit Open Box!')
 
         } catch (e) {
  
             toastError(e?.message)
             console.error(e?.message)
         }


    }

    return (
        <div className="project-box">


            <div className="content" style={{ marginTop: "0px" }}>

                <div style={{ width: "100%", height: "300px" }} >


                    {isNFT && <img style={{ width: "100%" }} className="mask" src={nft?.image} alt="Risebot" />
                    }

                    {!isNFT && <img style={{ width: "100%" }} className="mask" src={require('../../../assets/images/common/box.jpg')} alt="Risebot" />
                    }
                </div>

                <div className="content-inner">
                    {/*  <h5 className="heading"><Link to="/project_list">{ids}</Link></h5> */}

                    <ul>
                        <li>
                            <p className="text">NFT Id:</p>
                            <p className="price">{ids}</p>
                        </li>
                        <li>
                            <p className="text">NFT Level:</p>
                            <p className="price"><StyleInput  value =  {price} onChange={changePrice} style={{ width: "60px" }}  placeholder="Your price"/></p>
                        </li>
                    </ul>

                </div>

            </div>
            {isNFT && <Button title={t('Sell')} onClick={sellBox} addclass='style2' />}
            {!isNFT && <Button title={t('OPEN BOX')} onClick={openBox} addclass='style2' />}

        </div>
    );
}

export default Banner;