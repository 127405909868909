
const dataRoadmap = [
    {
        id: 1,
        time: 'April 16, 2022',
        text1: 'Cras molestie ullamcorper augue nec pulvinar',
        text2: 'Nam mollis sapien ut sapien gravida sollicitudin',
        text3: 'Mauris vel nisl quis dolor accumsan luctus',
        status: 'active'
    }, 
    {
        id: 2,
        time: 'April 16, 2022',
        text1: 'Cras molestie ullamcorper augue nec pulvinar',
        text2: 'Nam mollis sapien ut sapien gravida sollicitudin',
        text3: 'Mauris vel nisl quis dolor accumsan luctus',
        status: 'active'
    },
    {
        id: 3,
        time: 'April 16, 2022',
        text1: 'Cras molestie ullamcorper augue nec pulvinar',
        text2: 'Nam mollis sapien ut sapien gravida sollicitudin',
        text3: 'Mauris vel nisl quis dolor accumsan luctus',
        status: 'active'
    },
    {
        id: 4,
        time: 'April 16, 2022',
        text1: 'Cras molestie ullamcorper augue nec pulvinar',
        text2: 'Nam mollis sapien ut sapien gravida sollicitudin',
        text3: 'Mauris vel nisl quis dolor accumsan luctus',
        status: 'active'
    },
    {
        id: 5,
        time: 'April 16, 2022',
        text1: 'Cras molestie ullamcorper augue nec pulvinar',
        text2: 'Nam mollis sapien ut sapien gravida sollicitudin',
        text3: 'Mauris vel nisl quis dolor accumsan luctus',
        status: ''
    },
    {
        id: 6,
        time: 'April 16, 2022',
        text1: 'Cras molestie ullamcorper augue nec pulvinar',
        text2: 'Nam mollis sapien ut sapien gravida sollicitudin',
        text3: 'Mauris vel nisl quis dolor accumsan luctus',
        status: ''
    },
    {
        id: 7,
        time: 'April 16, 2022',
        text1: 'Cras molestie ullamcorper augue nec pulvinar',
        text2: 'Nam mollis sapien ut sapien gravida sollicitudin',
        text3: 'Mauris vel nisl quis dolor accumsan luctus',
        status: ''
    },
    {
        id: 8,
        time: 'April 16, 2022',
        text1: 'Cras molestie ullamcorper augue nec pulvinar',
        text2: 'Nam mollis sapien ut sapien gravida sollicitudin',
        text3: 'Mauris vel nisl quis dolor accumsan luctus',
        status: ''
    },


]

export default dataRoadmap;