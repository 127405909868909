import React, { useState ,useEffect} from 'react'

import PageTitle from '../components/pagetitle'
import CTA from '../components/cta/cta_v2'
import { Link } from 'react-router-dom'
 

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import {
  Box,
  alertVariants,
  Alert, 
  Button,Input,AddIcon,RemoveIcon
} from '@pancakeswap/uikit'
import data from '../assets/fake-data/data-project'
import {getContractHandler,_getAddress,useWalletHook} from '../utils/ethereum'
import {Constants} from '../utils/Config'
import useToast from '../utils/useToast'
import {ToastContainer} from '../utils/Toast'

import CopyAddress from '../utils/CopyAddress'

import { BigNumber, Contract, providers, utils } from 'ethers'
 function Project1(props) {
  const [dataTab] = useState([
    {
      id: 1,
      title: 'Bee Donations',
    },
    /* {
      id: 2,
      title: 'Upcoming',
    },
    {
      id: 3,
      title: 'Past IGO',
    }, */
  ])

  const [parent,setParent] = useState(0)
  const [numbe,setNumbe] = useState("0")
  const [buyNumber,setBuyNumber] = useState(1)
  const [toAddress,setToAddress] = useState("")

  const [invateLink,setInviteLink] = useState("https://pinft.vip?invite=")



  const { toastSuccess, toastError } = useToast()
  const env = useWalletHook()
  const queryNFT=async ()=>{
    const PiPandaNFT = await getContractHandler('PiPandaNFT')
    if(!PiPandaNFT) return;

    const total = await PiPandaNFT.totalSupply()

    setNumbe(utils.formatUnits(total,0)); 
    setParent(Number(utils.formatUnits(total,0) /150))
    
  }

  useEffect(  () => {
    if(env){
      queryAddInvite();
      queryNFT();
    }
        
  }, [env]);

 
  const queryAddInvite=async ()=>{
   
    const account =await _getAddress()
    if (account) {
      // 对字符串进行base64编码
  /*     let buffer = Buffer.from(account)
      console.log(buffer.toString('base64')) // MTIz */
      setInviteLink( "https://pinft.vip?invite="+ account)
      // 对base64编码字符串进行解码
      // 读取base64字符串
     /*  buffer = Buffer.from(buffer.toString('base64'), 'base64')
      // 进行解码
      console.log(buffer.toString()) // 123 */
    }
  }




  const buyUseUSDT= async ()=>{

    try {
  
      const usdt = await getContractHandler('USDT')
      const PiPandaMint = await getContractHandler('PiPandaMint')

      const account  =await _getAddress() 

      console.log("adddddd",toAddress)
 
      await PiPandaMint.mintBlindBox(toAddress,buyNumber )
      toastSuccess('Successfully submit Mint!')
       
    } catch (e) {
      
    toastError(e?.message)
      console.error(e?.message)
    }

  }



  
  const approve= async ()=>{

    try {

      const account  =await _getAddress()  
       const piPandaNFT = await getContractHandler('PiPandaNFT')
       if (!piPandaNFT) return;
       // const att = await PiPandaMint.openBox(ids)
       //检查授权
       //如果没有授权，先提示授权 
       await  piPandaNFT.setApprovalForAll( Constants.Contract.BatchOpen,true);
      
       toastSuccess('提交授权成功!')

   } catch (e) {

       toastError(e?.message)
       console.error(e?.message)
   }

  }

   




  return (
    <div className="inner-page">
      {<PageTitle />}
 

   

      <section className="tf-section project">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="flat-tabs"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <Tabs>
                  <div className="wrapper_menutab">
                    <TabList className="menu-tab">
                      {dataTab.map((idx) => (
                        <Tab className="fs-14 h6" key={idx.id}>
                          {idx.title}
                        </Tab>
                      ))}
                    </TabList>
                    <div
                      className="seclect-box"
                      data-aos="fade-in"
                      data-aos-duration="800"
                    >
                        
                    </div>
                  </div>

                  {dataTab.map((idx) => (
                    <TabPanel key={idx.id} className="content-tab mt40">
                      <div
                        style={{ margin: 'auto' }}
                        className="content-inner project-box-style6_wrapper"
                      >
                        <div className="project-box-style6">
                          <span className="boder"></span>
                          <div className="img-box">
                            <div className="image">
                              <img
                                style={{ width: '100%' }}
                                src={require('../assets/images/common/box.jpg')}
                                alt=""
                              />
                            </div>
                            <div className="content">
                              <div className="img-box">
                                <div className="image_inner">
                                  <img
                                    className="mask"
                                    src={require('../assets/images/common/p_5.jpg')}
                                    alt=""
                                  />
                                  <div className="shape">
                                    <img
                                      src={require('../assets/images/common/shape2.png')}
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="content-inner">
                                  <h5 className="heading">
                                    <Link to="/project_details">
                                      {'PIPANDA'}
                                    </Link>
                                  </h5>
                                  <p className="desc">{'BEE DESC'}</p>
                                </div>
                              </div>
                              <div className="content-bottom">
                                <div className="content-progress-box style2">
                                  <div
                                    className="progress-bar"
                                    data-percentage={parent}
                                  >
                                    <p className="progress-title-holder">
                                      <span className="progress-title">
                                        <span className="progress_number">
                                          <span className="white">
                                            Total Raised:{' '}
                                          </span>
                                          <span className="progress-number-mark">
                                            <span className="percent"></span>
                                          </span>
                                        </span>
                                        <span> {numbe} /  15,000</span>
                                      </span>
                                    </p>
                                    <div className="progress-content-outter">
                                      <div  style={{ width:  parent+'%'  }} className="progress-content"></div>
                                    </div>
                                  </div>
                                </div>
                               {/*  <div className="sub-content">
                                  <RemoveIcon onClick={()=>{
                                    if(buyNumber>1){
                                      setBuyNumber(buyNumber - 1)
                                    }  
                                  }} cursor={"pointer"} />
                                    <Input readOnly value={buyNumber}  style={{width:"60px"}}/>
                                  <AddIcon onClick={()=>{
                                     
                                      setBuyNumber(buyNumber + 1)
                                    
                                  }} cursor={"pointer"}/>
                                </div>

                                <div className="sub-content">
                                  
                                    <Input placeholder='请输入发送地址' onChange={(e)=>{
                                      setToAddress(e.target.value)
                                    }}  value={toAddress}  style={{width:"260px"}}/>
                                 
                                </div> */}
                                <div className="sub-content">

                                   {/*   <button type="submit" className="tf-button style2"onClick={(e)=>{
                                      setToAddress("")
                                    }} >
                                       清空地址
                                    </button> 
                                  
                                    <button type="submit" className="tf-button style2" onClick={buyUseUSDT}>
                                       Mint发送
                                    </button>  */}


                                    <button type="submit" className="tf-button style2" onClick={approve}>
                                       授权
                                    </button> 
                                  
                                </div>

                               
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  ))}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>

      {<CTA />}
    </div>
  )
}

export default Project1
