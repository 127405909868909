import React, { useState, useEffect } from 'react'

import PageTitle from '../components/pagetitle'
import CTA from '../components/cta/cta_v2'
import { Link } from 'react-router-dom'

import ProjectItem from '../components/project/project_detail'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import {
  Box,
  alertVariants,
  Alert,
  Button, Input, AddIcon, RemoveIcon
} from '@pancakeswap/uikit'

import data from '../assets/fake-data/data-project'
import { getContractHandler, _getAddress, _getProvider, useWalletHook } from '../utils/ethereum'
import { Constants } from '../utils/Config'
import useToast from '../utils/useToast'
import { useTranslation } from '../utils/contexts/Localization'

import { BigNumber, Contract, providers, utils } from 'ethers'
function Project3(props) {
  const [dataTab] = useState([
    {
      id: 1,
      title: 'MY NFT',
    },

  ])

  const [dataTokens, setDataTokens] = useState([])
  const [dataTokensBox, setDataTokensBox] = useState([])

  const [start, setStart] = useState(0)
  const [end, setEnd] = useState(6)
  const [total, setTotal] = useState(0)
  const [nowpage, setNowPage] = useState(1)
  const { t } = useTranslation();
  const [nowpageV, setNowPageV] = useState( )


  const { wallet, chain } = useWalletHook();


  const queryTokens = async () => {

    const PiPandaNFT = await getContractHandler('PiPandaNFT')

    console.log("PiPandaNFT", PiPandaNFT)
    console.log("PiPandaNFTwallet", wallet)
    if (!PiPandaNFT) return;

    const account = _getAddress();

    const totals = await PiPandaNFT.ERC721TokenIds(account, start, end)
    const balance = await PiPandaNFT.ERC721TokenIds(account, start, end)

    setTotal(utils.formatUnits(totals.balance._hex, 0));


    setDataTokens(totals.ids)
    setDataTokensBox(totals.boxs)
  }

  useEffect(() => {
    if (wallet && chain) {
      queryTokens();
    }

  }, [wallet + chain + start]);

  console.log("total", total)


  const changeNumber = (e)=>{
       setNowPageV(e.target.value); 
  }

  return (
    <div className="inner-page">
      {<PageTitle />}

      <section className="tf-section project">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="flat-tabs"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <Tabs>
                  <div className="wrapper_menutab">
                    <TabList className="menu-tab">
                      {dataTab.map((idx) => (
                        <Tab className="fs-14 h6" key={idx.id}>
                          {idx.title}
                        </Tab>
                      ))}
                    </TabList>
                    <div
                      className="seclect-box"
                      data-aos="fade-in"
                      data-aos-duration="800"
                    >
                      <div className="dropdown selector-drop" id="category">
                        <Link
                          to="#"
                          className="btn-selector btn-link"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="boder"></span>All access
                        </Link>
                        <ul className="dropdown-menu dropdown-menu-right">
                          <li
                            className="dropdown-item active"
                            data-toggle="modal"
                            data-target="#delete_client"
                          >
                            Forgotten Samurai
                          </li>
                          <li
                            className="dropdown-item"
                            data-toggle="modal"
                            data-target="#edit_client"
                          >
                            Star Batter
                          </li>
                          <li
                            className="dropdown-item"
                            data-toggle="modal"
                            data-target="#edit_client"
                          >
                            Calvary Deneral
                          </li>
                        </ul>
                      </div>
                      {/* <div className="dropdown selector-drop" id="artworks">
                        <Link
                          to="#"
                          className="btn-selector btn-link"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="boder"></span>All Block Chain
                        </Link>

                        <ul className="dropdown-menu dropdown-menu-right">
                          <li
                            className="dropdown-item active"
                            data-toggle="modal"
                            data-target="#delete_client"
                          >
                            Sort by Popularity
                          </li>
                          <li
                            className="dropdown-item"
                            data-toggle="modal"
                            data-target="#edit_client"
                          >
                            Sort by Latest
                          </li>
                          <li
                            className="dropdown-item"
                            data-toggle="modal"
                            data-target="#edit_client"
                          >
                            Sort by View
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>


                  <TabPanel className="content-tab mt40">
                    <div className="content-inner project-box-wrapper">
                      {dataTokens.map((item, index) => (
                        <ProjectItem key={item._hex} item={item} isBox={dataTokensBox[index]} />
                      ))}
                    </div>
                  </TabPanel>

                </Tabs>
              </div>
            </div>
            <div className="col-md-12">
              <ul
                className="panigation mt6"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <li>
                  <Link to="#" onClick={() => {

                    if (nowpage > 1) {
                      setStart((nowpage - 1) * 6);
                      setEnd((nowpage) * 6);
                      setNowPage(nowpage - 1);
                    }

                  }} >
                    <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"


                    >
                      <path
                        d="M6.5 1L1.5 6L6.5 11"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Link>
                </li>

                <li>

                  <Link key={"li" + 1} className="active"  >
                    {nowpage}
                  </Link>
                </li>

                {/*   <li>
                      <Link  key={"li"+3} className="active"  >
                        { Math.ceil(Number(total)/6) -1}
                      </Link>
                    </li> */}




                <li>
                  <Link to="#">
                    <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"

                      onClick={() => {

                        if (nowpage < Math.ceil(Number(total) / 6)) {
                          setStart((nowpage) * 6);
                          setEnd((nowpage + 1) * 6);
                          setNowPage(nowpage + 1);
                        }

                      }}
                    >
                      <path
                        d="M1.5 1L6.5 6L1.5 11"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Link>
                </li>
              </ul>
            </div>


            <div className="col-md-12">

              <ul
                className="panigation mt6"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                  <li>  <span>{t("Total ")}：{total}</span>&nbsp;&nbsp;&nbsp;</li>
                  <li>  <span>   </span></li>
                 <li>  <span>{t("Total Page")} ：{Math.ceil(Number(total) / 6)}</span>&nbsp;&nbsp;&nbsp;</li>
              
                 <li>  <Input  onChange={changeNumber} value={nowpageV} style={{ width: "60px" }}/> </li>
                 <li>  <Button style={{marginLeft:"10px"}} onClick={()=>{
                    if( nowpageV&&nowpageV>0&& nowpageV<= Math.ceil(Number(total)) ){
                      setNowPage(nowpageV);
                      setStart((nowpageV-1)*6);
                      setEnd((nowpageV)*6);
                    } 
                 }
                
                 }>{t("Jump")}</Button></li>
              </ul>


            </div>
          </div>
        </div>
      </section>

      {<CTA />}
    </div>
  )
}

export default Project3
