import React, { useEffect, useState } from 'react'

import './styles.scss'
import { useTranslation } from '../../utils/contexts/Localization'

import { Link } from 'react-router-dom'

function Footer(props) {
  const [isVisible, setIsVisible] = useState(false)
  const { t } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    window.addEventListener('scroll', toggleVisibility)

    return () => window.removeEventListener('scroll', toggleVisibility)
  }, [])
  return (
    <footer id="footer">
      <div className="footer-main">
        <div className="container">
          <div className="row">
            <div className="footer-logo">
              <div className="logo_footer">
                <img
                  src={require('../../assets/images/logo/donat.png')}
                  alt=""
                />
              </div>
              <p>{t("A one-stop destination for web3 gaming.")}</p>
            </div>
            {/* <div className="widget">
              <h5 className="widget-title">Contact us</h5>
              <ul className="widget-link contact">
                <li>
                  <p>Address</p>
                  <Link to="#">1901 Thornridge Cir. Shiloh, Hawaii 81063</Link>
                </li>
                <li>
                  <p>Phone</p>
                  <Link to="#">+33 7 00 55 57 60</Link>
                </li>
                <li className="email">
                  <p>Email</p>
                  <Link to="#">pipanda@support.com</Link>
                </li>
              </ul>
            </div> */}
            {/* <div className="widget support">
              <h5 className="widget-title">Support</h5>
              <ul className="widget-link">
                <li>
                  <Link to="/connect">Connect Wallet</Link>
                </li>
                <li>
                  <Link to="/forgetPass">Forget Password</Link>
                </li>
                <li>
                  <Link to="/faqs">FAQs</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div> */}
            {/* <div className="widget link">
              <h5 className="widget-title">Quick link</h5>
              <ul className="widget-link">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/project_list">Project</Link>
                </li>
                <li>
                  <Link to="/blog_grid">Blog</Link>
                </li>
                <li>
                  <Link to="/team_details">Our Team</Link>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="wrap-fx">
            <div className="Copyright">
              Copyright © 2024. Bee
              {/*  Designed by{' '}
              <Link to="https://themeforest.net/user/themesflat/portfolio">
                Themesflat
              </Link> */}
            </div>
            <ul className="social">
              <li>
                <Link to="https://x.com/BEE_KINGDOM1">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_157_2529)">
                      <path
                        d="M18 3.41887C17.3306 3.7125 16.6174 3.90712 15.8737 4.00162C16.6388 3.54487 17.2226 2.82712 17.4971 1.962C16.7839 2.38725 15.9964 2.68763 15.1571 2.85525C14.4799 2.13413 13.5146 1.6875 12.4616 1.6875C10.4186 1.6875 8.77387 3.34575 8.77387 5.37863C8.77387 5.67113 8.79862 5.95237 8.85938 6.22012C5.7915 6.0705 3.07687 4.60013 1.25325 2.36025C0.934875 2.91263 0.748125 3.54488 0.748125 4.2255C0.748125 5.5035 1.40625 6.63637 2.38725 7.29225C1.79437 7.281 1.21275 7.10888 0.72 6.83775C0.72 6.849 0.72 6.86363 0.72 6.87825C0.72 8.6715 1.99912 10.161 3.6765 10.5041C3.37612 10.5863 3.04875 10.6256 2.709 10.6256C2.47275 10.6256 2.23425 10.6121 2.01038 10.5626C2.4885 12.024 3.84525 13.0984 5.4585 13.1332C4.203 14.1154 2.60888 14.7071 0.883125 14.7071C0.5805 14.7071 0.29025 14.6936 0 14.6565C1.63462 15.7106 3.57188 16.3125 5.661 16.3125C12.4515 16.3125 16.164 10.6875 16.164 5.81175C16.164 5.64862 16.1584 5.49113 16.1505 5.33475C16.8829 4.815 17.4982 4.16587 18 3.41887Z"
                        fill="#798DA3"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_157_2529">
                        <rect width="18" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </Link>
              </li>
               
              <li>
                <Link to="https://t.me/Bee_Kingdomai">
               {/*    <svg
                    width="18"
                    height="12"
                    viewBox="0 0 18 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >

                          <path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"   fill="#798DA3"></path>
 
                   
                  </svg> */}

                  <svg width="24px" height="24px"   fill="none"  viewBox="0 0 24 24"  
                  xmlns="http://www.w3.org/2000/svg"> <g clipPath="url(#clip0_157_2529)">
                    <path d="M23.91 3.79L20.3 20.84c-.25 1.21-.98 1.5-2 .94l-5.5-4.07-2.66 2.57c-.3.3-.55.56-1.1.56-.72 0-.6-.27-.84-.95L6.3 13.7l-5.45-1.7c-1.18-.35-1.19-1.16.26-1.75l21.26-8.2c.97-.43 1.9.24 1.53 1.73z"
                     fill="#798DA3"/>
                    </g>
 
                    </svg>
               
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {isVisible && <Link onClick={scrollToTop} to="#" id="scroll-top"></Link>}
    </footer>
  )
}

export default Footer
