import React, { useState, useEffect } from 'react'

import PageTitle from '../components/pagetitle'
import CTA from '../components/cta/cta_v2'
import { Link } from 'react-router-dom'

import { useTranslation } from '../utils/contexts/Localization'


import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import {
  Box,
  alertVariants,
  Alert,
  Button, Input, AddIcon, RemoveIcon
} from '@pancakeswap/uikit'
import data from '../assets/fake-data/data-project'
import { getContractHandler, _getAddress, useWalletHook ,watchTransaction} from '../utils/ethereum'
import { Constants } from '../utils/Config'
import useToast from '../utils/useToast'
import { ToastContainer } from '../utils/Toast'

import CopyAddress from '../utils/CopyAddress'

import { BigNumber, Contract, providers, utils } from 'ethers'
function Project1(props) {

  const { t } = useTranslation();

  const [dataTab] = useState([
    {
      id: 1,
      title: t('Bee Donations'),
    },
  
  ])

  const [parent, setParent] = useState(0)
  const [numbe, setNumbe] = useState("0")
  const [mynumbe, setMyNumbe] = useState("0")

  const [buyNumber, setBuyNumber] = useState("0.1")

 
  const [loadding,setLoadding] = useState(false)
  const [invateLink, setInviteLink] = useState("#")


  const [myInviteorders, setMyinviteorders] = useState([])

  const { toastSuccess, toastError } = useToast()
  const {wallet, chain} = useWalletHook()
  const getData = async () => {
    const Bee = await getContractHandler('Bee')
    if (!Bee) return;

    const total = await Bee.totalAmount()
    const account = await _getAddress()
    const mytotal = await Bee.myDonat(account)


    setMyNumbe( Number(utils.formatEther(mytotal)).toFixed(3));


    setNumbe(Number(utils.formatEther(total)).toFixed(3));
    setParent(Number(utils.formatEther(total) / 300)) 

    //查詢個人訂單
 



  }


  const getData2 = async () => {
    console.log("zhixingssada")
    const Bee = await getContractHandler('Bee')
    if (!Bee) return;
   
    console.log("query:dadadadaada",wallet)
    if(wallet){
      
      //查詢個人邀請

      for (let indexss = 0; indexss < 15; indexss++) {
        try {
              console.log("xontijjsjsjsss",indexss)
            const ress = await Bee.inviteOrderIds(wallet,indexss) 
            console.log("ressressress",ress,utils.formatUnits(ress,0))
            if(ress>0){
              const res =     await Bee.tokenDeposits(utils.formatUnits(ress,0)) 
              if(res){
                console.log("resresres",res.timestamp)
                setMyinviteorders([...myInviteorders, res]);
              }else{
                break
              }
        
            }else{
              break
            }
        } catch (error) {
          console.log(error)
          break
        }
        
      }
      
    }
  }


  useEffect(() => {
 
    console.log("walletwallet",wallet)
    if (wallet) {
 
      getData();
     
      queryAddInvite();

      setTimeout( getData2,100)
    }

  }, [wallet]);

  const queryAddInvite = async () => {

    const account = await _getAddress()
    if (account) {
      // 对字符串进行base64编码
      /*     let buffer = Buffer.from(account)
          console.log(buffer.toString('base64')) // MTIz */
      setInviteLink("https://beekingdom.world?invite=" + account)
      // 对base64编码字符串进行解码
      // 读取base64字符串
      /*  buffer = Buffer.from(buffer.toString('base64'), 'base64')
       // 进行解码
       console.log(buffer.toString()) // 123 */
    }
  }



  const buyUseUSDT = async () => {

    try {
      setLoadding(true)


      const usdt = await getContractHandler('USDT')
      const Bee = await getContractHandler('Bee')

      const account = await _getAddress()

      const myUSDT = await usdt.balanceOf(account)

    /*   if (parseFloat(utils.formatUnits(myUSDT,18)) <= utils.parseUnits(buyNumber,0) ) {
        toastError("Insufficient funds")
        setLoadding(false)
        return;
      } */

      const approveBee = await usdt.allowance(account, Constants.Contract.Bee)
      if (parseFloat(utils.formatEther(approveBee)) <=utils.parseUnits(buyNumber,0) ) {
        const hashApprove = await usdt.approve(Constants.Contract.Bee, "0xffffffffffffffffffffffffffffffffffffffff")

        watchTransaction(hashApprove.hash,(res)=>{
            buyUseUSDT()
        })

        return false;
      }
 
      const invite_address = localStorage.getItem('invite_address')
        ? localStorage.getItem('invite_address')
        : '0x0000000000000000000000000000000000000000'
      console.log('invite_addressInwarrior', invite_address)

      const hash =    await Bee.donations(utils.parseEther(buyNumber),invite_address)

      watchTransaction(hash.hash,(res)=>{
        console.log(res)
        setLoadding(false)
        getData()
        toastSuccess(t('Successfully submit!'))
    })

      

    } catch (e) {
      setLoadding(false)

      toastError(e?.reason||e?.message)
      console.error(e?.reason)
    }

  }



  const buyUseMatic = async () => {

    try {


      const Bee = await getContractHandler('Bee')

      const account = await _getAddress()
 

      const invite_address = localStorage.getItem('invite_address')
        ? localStorage.getItem('invite_address')
        : '0x0000000000000000000000000000000000000000'
      console.log('invite_addressInwarrior', invite_address)
      const hash =   await Bee.donationsBNB(invite_address, { from: account, value: (utils.parseEther(buyNumber)  ) + "" })
      watchTransaction(hash.hash,(res)=>{
        console.log(res)
        setLoadding(false)
        getData()
        toastSuccess(t('Successfully submit!'))
    })

    } catch (e) {

      toastError(e?.reason||e?.message)
      console.error(e?.reason)
    }

  }

  const handleChange = (e) => {
    console.log("eee",e.target.value)
    const value2 = e.target.value;
    // 正则表达式验证小数和整数
    const regex = /^-?\d*\.?\d*$/;
    if (regex.test(value2) || value2 === '') { // 允许空输入
      setBuyNumber(value2);
    }
  };


  return (
    <div className="inner-page">
      {<PageTitle />}




      <section className="tf-section project">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="flat-tabs"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <Tabs>
                  <div className="wrapper_menutab">
                    <TabList className="menu-tab">
                      {dataTab.map((idx) => (
                        <Tab className="fs-14 h6" key={idx.id}>
                          {idx.title}
                        </Tab>
                      ))}
                    </TabList>
                    <div
                      className="seclect-box"
                      data-aos="fade-in"
                      data-aos-duration="800"
                    >
                     
                    </div>
                  </div>

                  {dataTab.map((idx) => (
                    <TabPanel key={idx.id} className="content-tab mt40">
                      <div
                        style={{ margin: 'auto' }}
                        className="content-inner project-box-style6_wrapper"
                      >
                        <div className="project-box-style6">
                          <span className="boder"></span>
                          <div className="img-box">
                            <div className="image">
                            
                            </div>
                            <div className="content">
                              <div className="img-box">
                                <div className="image_inner">
                                  <img
                                    className="mask"
                                    src={require('../assets/images/common/donat.png')}
                                    alt=""
                                  />
                                  <div className="shape">
                                   
                                  </div>
                                </div>
                                <div className="content-inner">
                                  <h5 className="heading">
                                   {/*  <Link to="/project_details">
                                      {'Bee Kingdom'}
                                    </Link> */}

                                    <Link to="#">
                                      {'Bee Kingdom'}
                                    </Link> 
                                  </h5>
                                  <p className="desc">{t('Busy Little Bees, come and build your bee kingdom!')}</p>
                                </div>
                              </div>
                              <div className="content-bottom">
                                <div className="content-progress-box style2">
                                  <div
                                    className="progress-bar"
                                    data-percentage={parent}
                                  >
                                    <p className="progress-title-holder">
                                      <span className="progress-title">
                                        <span className="progress_number">
                                          <span className="white">
                                            {t("Total")}:{' '}
                                          </span>
                                          <span className="progress-number-mark">
                                            <span className="percent"></span>
                                          </span>
                                        </span>
                                        <span> {numbe} /  30,000 USDT</span>
                                      </span>
                                    </p>
                                    <div className="progress-content-outter">
                                      <div style={{ width: parent + '%' }} className="progress-content"></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="sub-content">
                            
                                  <Input  onChange={handleChange}  value={buyNumber} style={{ width: "160px" }} />
                              
                                </div>
                                <div className="sub-content">

                                  <button type="submit" style={{ background:loadding?"#798DA3":"#a526f0" }}  className="tf-button style2" onClick={buyUseUSDT} >
                                    {t('Donated Items')}(USDT)
                                  </button>



                                  <button style={{ marginLeft: "10px",background:loadding?"#798DA3":"#a526f0" }}  type="submit" onClick={buyUseMatic} className="tf-button style2">
                                    {t('Donated Items')}(BNB)
                                  </button>

                                </div>

                                <div className="sub-content">
                                <CopyAddress account={invateLink} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  ))}
                </Tabs>
              </div>
            </div>
            <p>{t("My Donate")}({mynumbe})</p>
          

          </div>
        </div>
      </section>


      <section className="tf-section project_2">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="tf-title left mb40"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <h2 className="title">{t("My Invite")}</h2>
              </div>
            </div>
            <div className="col-md-12">
              <div className="project-box-style3_wrapper">
                {myInviteorders.map((item,idx) => (
                  <div
                    key={item.timestamp}
                    className="project-box-style3"
                    data-aos="fade-in"
                    data-aos-duration="800"
                  >
                    <div className="header_project">
                      <div className="image">
                        
                        <div className="shape">
                           
                        </div>
                      </div>
                      <h5 className="heading">
                        <Link to="/project_details">{idx+1}</Link>
                      </h5>
                    </div>
                    <div className="content">
                      <div className="td td1">
                        <p>Number</p>
                        <p>{Number(utils.formatUnits(item.amount,18)).toFixed(6)}</p>
                      </div>
                   <div className="td td2">
                        <p>Address</p>
                        <p>{"0x.."+item.user.substring(35,42)}</p>
                      </div>
                      <div className="td td3">
                        <p>BNB</p>
                        <p>{utils.formatUnits(item.bamount,18)}</p>
                      </div>
                      <div className="td td4">
                        <p>Chain</p>
                        <p>
                        <p>{item.coin}</p>
                        </p>
                      </div>  
                       
                       
                    </div>
                  </div>
                ))}
              </div>
            </div>
            
          </div>
        </div>
      </section>

      {<CTA />}
    </div>
  )
}

export default Project1
